<template>
    <el-card shadow="never">
        <div class="review-container">
            <div class="image">
                <el-image fit="scale-down" :src="itemImage"></el-image>
                <el-rate class="rate" :value="item.rating" disabled> </el-rate>
            </div>
            <div class="details">
                <div class="top">
                    <div class="name">
                        <el-link :underline="false" type="primary" :href="itemUrl" target="_blank" v-html="item.name">
                        </el-link>
                    </div>
                    <div class="date" v-html="item.date"></div>
                </div>
                <div class="middle">
                    <ShowMore :text="transformedText" class="text"> </ShowMore>
                    <div class="response">
                        <el-input
                            type="text"
                            :placeholder="$t('products.reviews.write-response')"
                            maxlength="3000"
                            show-word-limit
                            v-model="response"
                            @keyup.enter.native="submitAnswer"
                        >
                            <el-button slot="append" icon="el-icon-s-promotion" @click="submitAnswer"></el-button>
                        </el-input>
                        <el-button
                            size="small"
                            type="success"
                            class="btn-approve"
                            plain
                            icon="el-icon-check"
                            @click="approveReview"
                        ></el-button>
                        <el-button
                            size="small"
                            type="danger"
                            class="btn-delete"
                            plain
                            icon="el-icon-delete"
                            @click="deleteReview"
                        ></el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-card>
</template>

<script>
import { get, call, sync } from 'vuex-pathify';
const ShowMore = () => import('@/components/ShowMore/index');
export default {
    name: 'ReviewItem',
    props: ['item'],
    components: {
        ShowMore
    },
    data: () => ({
        response: ''
    }),
    computed: {
        itemUrl() {
            return this.$store.get('app/siteBase') + this.item.url;
        },
        itemImage() {
            return this.$store.get('app/imageBase') + this.item.image;
        },
        transformedText() {
            return window.unescape(this.item.reviews_text);
        }
    },
    methods: {
        submitResponse: call('products/SubmitResponse'),
        removeReview: call('products/DeleteReview'),
        approveReviewAction: call('products/ApproveReview'),
        async submitAnswer() {
            if (!this.response || !this.response.trim()) {
                return this.sbMsg({
                    message: this.$t('products.reviews.no-response-error'),
                    type: 'error'
                });
            }
            if (this.response.trim().length < 10) {
                return this.sbMsg({
                    message: this.$t('products.reviews.short-response-error'),
                    type: 'error'
                });
            }

            try {
                await this.submitResponse({
                    response: this.response.trim(),
                    reviewId: this.item.reviewId
                });
            } catch (err) {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'SubmitAnswer'
                    }
                });
            }
        },
        async deleteReview() {
            this.$confirm(this.$t('products.reviews.confirm-delete'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.cancel'),
                type: 'warning'
            })
                .then(async () => {
                    try {
                        await this.removeReview({
                            reviewId: this.item.reviewId
                        });
                    } catch (err) {
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'deleteReview'
                            }
                        });
                    }
                })
                .catch((e) => {});
        },
        async approveReview() {
            this.$confirm(this.$t('products.reviews.confirm-approve'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.cancel'),
                type: 'warning'
            })
                .then(async () => {
                    try {
                        await this.approveReviewAction({
                            reviewId: this.item.reviewId
                        });
                    } catch (err) {
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'approveReview'
                            }
                        });
                    }
                })
                .catch((e) => {});
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body {
    padding: 10px;
}

::v-deep .el-divider {
    background-color: #dcdfe6;
    position: relative;
    height: 100%;
}

::v-deep .el-divider--vertical {
    display: inline-block;
    width: 1px;
    height: 1em;
    margin: 0 8px;
    vertical-align: middle;
    position: relative;
}

.review-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100px;

    .image {
        width: 130px;
        flex: 0 0 130px;
        text-align: center;
        align-self: center;

        ::v-deep img {
            width: auto;
            max-width: 100px;
            height: auto;
            max-height: 100px;
        }

        .rate {
            margin-top: 10px;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: stretch;
        margin-left: 20px;
        font-size: 14px;

        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .date {
                max-height: 22px;
                font-size: 11px;
                font-weight: 700;
                border: 1px solid #ff8a65;
                padding: 5px;
                color: #ff8a65;
                border-radius: 0px 4px 0px 4px;
            }

            .name {
                color: #ff5722;
                font-weight: 700;
                margin-right: 15px;
            }
        }

        .middle {
            min-height: 115px;
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: stretch;

            .text {
                margin-top: 10px;
            }

            .response {
                background: #fff;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;

                .btn-delete,
                .btn-approve {
                    margin-left: 10px;
                }

                ::v-deep .el-input__count {
                    // top: 3px;
                    // position: absolute;
                    // right: 0;
                    font-size: 11px;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .review-container {
        flex-direction: column;

        .top {
            flex-direction: column !important;
            margin-top: 10px;
            text-align: center;

            .name {
                margin-bottom: 5px;
            }
        }
    }
}

::v-deep .el-input-group {
    display: flex;
}

::v-deep .el-input-group__append .el-select,
.el-input-group__append .el-button,
.el-input-group__prepend .el-select,
.el-input-group__prepend .el-button {
    margin: -2px -20px;
}
</style>
